/* eslint-disable arrow-body-style */
// Angular
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
// RxJS
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  ApiError,
  CustomError,
} from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { Logout } from '../actions/auth.actions';

import {
  UserActionTypes,
  UserLoaded,
  UserRequested,
} from '../actions/user.actions';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { User } from 'src/app/models/user.model';

// Services

@Injectable()
export class UserEffects {
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserRequested>(UserActionTypes.userRequested),
      mergeMap(() => {
        return this.userService.getUser();
      }),
      map((user: User) => new UserLoaded({ user })),
      catchError((error: CustomError | ApiError) => {
        //If ApiError we manage the error on apiService showing modal there
        //We show toast when customError only
        if (error instanceof CustomError) {
          const message: string =
            error && error.error && error.error.message
              ? error.error.message
              : 'Ha habido un error.';
          this.utilitiesService.errorToast(message);
        }
        return of(new Logout());
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private utilitiesService: UtilitiesService
  ) {}


}
