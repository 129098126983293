import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { autoLoginGuard } from './guards/auto-login.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/public/public.routes').then((m) => m.routes),
    canActivate: [autoLoginGuard],
  },
  {
    path: '',
    loadChildren: () => 
       import('./pages/private/private.routes').then((m) => m.routes),
    canActivate: [authGuard],
  },
  {
      path: '**',
      redirectTo: 'auth/login',
      pathMatch: 'full',
  }
];
