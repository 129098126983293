/* eslint-disable @typescript-eslint/naming-convention */
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { ModalController } from '@ionic/angular/standalone';
  import { Observable } from 'rxjs';
  import { catchError, map } from 'rxjs/operators';
  import { ApiErrorModalComponent } from 'src/app/components/api-error-modal/api-error-modal.component';
  import { ApiResponse } from 'src/app/interfaces';
  import { environment } from 'src/environments/environment';
  @Injectable({
    providedIn: 'root',
  })
  export class ApiService {
    token = '';
    constructor(private http: HttpClient, private modalCntrl: ModalController) {}
  
    getHeaders(): HttpHeaders {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      });
      return headers;
    }
  
    //PUBLIC REQUESTS
    authPost<T>(url: string, body: any = null): Observable<T> {
      return this.http.post(`${environment.server}/${url}`, body, ).pipe(
        map((response: ApiResponse<T>) => this.handleResponse<T>(response)),
        //Catch API errors
        catchError((error: HttpErrorResponse | CustomError) => {
          throw this.handleError(error);
        })
      );
    }
    //PRIVATE REQUESTS
  
    //GET
    get<T>(url: string): Observable<T> {
      const headers = this.getHeaders();
      return this.http
        .get<ApiResponse<T>>(`${environment.server}/${url}`, {
          headers,
        })
        .pipe(
          map((response: ApiResponse<T>) => this.handleResponse<T>(response)),
          //Catch API errors
          catchError((error: HttpErrorResponse | CustomError) => {
            throw this.handleError(error);
          })
        );
    }
    //POST
    post<T>(url: string, body: any = null): Observable<T> {
      return this.http.post<ApiResponse<T>>(`${environment.server}/${url}`, body, { headers: this.getHeaders() }).pipe(
        map((response: ApiResponse<T>) => this.handleResponse<T>(response)),
        catchError((error: HttpErrorResponse | CustomError) => {
          throw this.handleError(error);
        })
      );
    }
  
    //DELETE
  
    /*
     * We manage the response to show one type of error or another.
     * If it is a controlled "error" with response 200 we return the response,
     * otherwise we look at what error it is to show one modal or another.
     */
    handleResponse<T>(response: ApiResponse<T>) {
      console.log('response: ', response);
      //Check If response have custom "errors" (normal responses with status 200 but we return error instead of data)
      if (response.error) {
        //This handleError function will catch this thow.
        throw new CustomError(response);
      }
      return response.data as T;
    }
  
    handleError(error: HttpErrorResponse | CustomError) {
      //If its customError we return the error to manage it on the view
      if (error instanceof CustomError) {
        return error;
      }
      //If its HttpErrorResponse we manage the error on this service opening modal.
      console.error('Layer Error: API');
      console.error(error);
      this.catchApiErrors(error);
      return ApiError.fromHttpErrorResponse(error);
    }
  
    //CATCH API ERRORS
    catchApiErrors(error: HttpErrorResponse) {
      switch (error.status) {
        case 503:
          this.showApiErrorModal('maintenance');
          break;
        case 426:
          this.showApiErrorModal('version');
          break;
        default:
          this.showApiErrorModal('maintenance');
          break;
      }
    }
  
    async showApiErrorModal(type: string) {
      const modal = await this.modalCntrl.create({
        component: ApiErrorModalComponent,
        componentProps: {
          type,
        },
        cssClass: 'api-error',
        backdropDismiss: false,
      });
  
      await modal.present();
    }
  }
  
  export class ApiError {
    static fromHttpErrorResponse(errorResponse: HttpErrorResponse): ApiError {
      return new ApiError(
        errorResponse.error,
        errorResponse.headers,
        errorResponse.status,
        errorResponse.statusText,
        errorResponse.url ?? ''
      );
    }
  
    constructor(
      public error?: any,
      public headers?: HttpHeaders,
      public status?: number,
      public statusText?: string,
      public url: string = ''
    ) {}
  }
  export class CustomError extends HttpErrorResponse {}