import { Action } from '@ngrx/store';
import { User } from 'src/app/models/user.model';


export enum UserActionTypes {
  userRequested = '[User Module] UserRequested',
  userLoaded = '[User Module] User Loaded',
  clearUserAction = '[Store] User Clear',
}

export class UserRequested implements Action {
  readonly type = UserActionTypes.userRequested;
  constructor() {}
}

export class UserLoaded implements Action {
  readonly type = UserActionTypes.userLoaded;
  constructor(public payload: { user: User }) {}
}
export class ClearUserAction implements Action {
  readonly type = UserActionTypes.clearUserAction;
  constructor() {}
}

export type UserAction = UserRequested | UserLoaded | ClearUserAction;
