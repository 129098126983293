import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { Workbox } from 'workbox-window';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { provideStore } from '@ngrx/store';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { versionInterceptor } from './app/interceptors/version.interceptor';
import { reducers } from './app/core/reducers';
import { provideStoreDevtools, StoreDevtoolsModule } from '@ngrx/store-devtools';
import { isDevMode } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { UserEffects } from './app/core/effects/user.effects';
import { provideAnimations } from '@angular/platform-browser/animations';

bootstrapApplication(AppComponent, {
  
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({mode: 'ios'}),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideStore(reducers), provideEffects(UserEffects), provideHttpClient(/*withInterceptors([versionInterceptor])*/),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true // If set to true, the connection is established within the Angular zone
    }),
    provideAnimations()
],
}).then(() => {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('firebase-messaging-sw.js');

    wb.register().then((registration) => {
      console.log('Service worker registered');
      registration.update();
    }).catch(error => {
      console.error('Service worker registration failed:', error);
    });
  }
});
