/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Store } from '@ngrx/store';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  ClearUserAction,
  UserRequested,
} from 'src/app/core/actions/user.actions';
import { AppState } from 'src/app/core/reducers/index';
import { ApiResponse, Auth, AuthCodeCheck, AuthRecovery, AuthSetPassword } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

const REGISTER_URL = 'auth/register';
const LOGIN_URL = 'auth/login';
const RESET_PWD_URL = 'password-reset';
const AUTH_TOKEN_KEY = 'ACCESS_TOKEN';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private apiService: ApiService
  ) {
    this.loadToken();
  }

  async loadToken() {
    const token = await Preferences.get({ key: AUTH_TOKEN_KEY });
    if (token && token.value) {
      this.apiService.token = token.value;
      this.loadUserInfo();
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  register(params: any): Observable<any> {
    return this.http
      .post(`${environment.server}/${REGISTER_URL}`, params)
      .pipe(map((data) => data));
  }

  login(credentials: {
    email: string;
    password: string;
    device_platform: string;
    device_lang: string;
  }): Observable<any> {
    return this.apiService.authPost<Auth>(LOGIN_URL, credentials).pipe(
      map((data: Auth) => {
        this.apiService.token = data.token;
        this.loadUserInfo();
        return from(
          Preferences.set({ key: AUTH_TOKEN_KEY, value: data.token })
        );
      }),
      tap((_) => {
        this.isAuthenticated.next(true);
      })
    );
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);

    //Clear store dispatches
    this.store.dispatch(new ClearUserAction());

    return Preferences.remove({ key: AUTH_TOKEN_KEY });
  }

  loadUserInfo() {
    // Load initial user data
    this.store.dispatch(new UserRequested());
  }

  // PASSWORD RESET PROCESS
  // Get code
  getPasswordResetCode(params: any): Observable<AuthRecovery> {
    return this.apiService.authPost<AuthRecovery>(RESET_PWD_URL + '/email', params);
  }

  // Check code
  checkPasswordResetCode(params: any): Observable<AuthCodeCheck> {
    return this.apiService.authPost<AuthCodeCheck>(RESET_PWD_URL + '/code/check', params);
  }

  // Reset password
  setNewPassword(params: any): Observable<AuthSetPassword> {
    return this.apiService.authPost<AuthSetPassword>(RESET_PWD_URL + '/reset', params);
  }
}
