<ion-content class="ion-padding">
  <ng-container *ngIf="type === 'maintenance'">
    <div class="container ion-padding">
      <img style="width: 60%;" src="assets/icons/favicon.png" alt="logo" />
      <h1> Aplicación en mantenimiento</h1>
      <p>
        Lo sentimos, actualmente la aplicación no está disponible debido a un mantenimiento programado.
      </p>
      <ion-button
        expand="block"
        color="primary"
        fill="outline"
        shape="round"
        (click)="close()"
        >Reintentar</ion-button
      >
    </div>
  </ng-container>
</ion-content>
