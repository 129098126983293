import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from '../interfaces';
import { ApiError, ApiService, CustomError } from './api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../models/user.model';

const USER_URL = 'me';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User | undefined;

  constructor(private apiService: ApiService) {}

  getUser(): Observable<User> {

    return of({
      id: 1,
      email: 'test@test.com',
      active: 1
    })

    // return this.apiService.get<User>(USER_URL).pipe(
    //   map((data: User) => {
    //     this.user = data;
    //     return data;
    //   })
    // );
  }
}
