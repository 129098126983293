import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private toastController: ToastController) { }

  async successToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 4000,
      cssClass: 'success-toast'
    });
    toast.present();
  }

  async errorToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 4000,
    });
    toast.present();
  }
}
