/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from 'src/app/models/user.model';

import { UserAction, UserActionTypes } from '../actions/user.actions';

export interface UserState extends EntityState<User> {
  firstLoadDone: boolean;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialUserState: UserState = adapter.getInitialState({
  firstLoadDone: false,
});

export function userReducer(
  state = initialUserState,
  action: UserAction
): UserState {
  switch (action.type) {
    case UserActionTypes.userLoaded: {
      return adapter.addOne(action.payload.user, {
        ...initialUserState,
        firstLoadDone: true,
      });
    }
    case UserActionTypes.clearUserAction: {
      return initialUserState;
    }

    default:
      return state;
  }
}
