import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private toastController: ToastController) { }

  async successToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 4000,
      cssClass: 'success-toast'
    });
    toast.present();
  }
  async errorToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 6000,
      color: 'danger',
      cssClass: 'error-toast',
      position: 'top',
      buttons: [
        {
          text: 'Close',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  openCalendar(calendar: HTMLElementTagNameMap['ion-datetime']) {
    calendar.style.display = 'block';
    (calendar as any).open();
}
}
