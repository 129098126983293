import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  logout = '[App] Logout',
}

export class Logout implements Action {
  readonly type = AuthActionTypes.logout;
  constructor() {}
}

export type AuthAction = Logout;
