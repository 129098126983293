import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ModalController, IonContent, IonButton } from '@ionic/angular/standalone';

//TODO CANVIAR LA URL
const URL_IOS_STORE = 'itms-apps://apps.apple.com/es/app/'; //mi-registro/id1550976884';
const URL_GOOGLE_STORE = 'https://play.google.com/store/apps/'; //details?id=com.miregistro.app';

@Component({
  selector: 'app-api-error-modal',
  templateUrl: './api-error-modal.component.html',
  styleUrls: ['./api-error-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, IonContent, IonButton]
})
export class ApiErrorModalComponent {
  @Input() type: string = 'maintenance';
  pltfrm: string = 'android';

  constructor(
    private modalCntrl: ModalController,
    private platform: Platform
  ) {
    if (this.platform.ANDROID) {
      this.pltfrm = 'android';
    } else if (this.platform.IOS) {
      this.pltfrm = 'ios';
    }
  }

  ngOnInit() {
  }

  close() {
    this.modalCntrl.dismiss();
  }
  openStore() {
    if (this.pltfrm === 'android') {
      //Android
      window.open(URL_GOOGLE_STORE);
    } else if (this.pltfrm === 'ios') {
      //iOS
      window.open(URL_IOS_STORE);
    }
  }
}
